<template>
  <div class="page-container">
    <div class="flex justify-center mtb-30" v-if="id === 'primaryRed'">
      <button id="" class="rounded-full hover-red hover-font-white red-100">
        <div class="btn-txt font-red">Open Application</div>
        <div class="icon icon-red">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 16"
            width="16"
          >
            <path
              class="icon-svg"
              d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                                .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                                16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                                8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                                12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                                8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                                12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                                8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                                0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"
            ></path>
          </svg>
        </div>
      </button>
    </div>

    <div
      class="flex justify-center mtb-30"
      v-else-if="id === 'primaryDisabled'"
    >
      <button disabled id="" class="black rounded-full hover-red disabled">
        <div class="btn-txt font-white">Close Application</div>
        <div class="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 16"
            width="16"
          >
            <path
              class="icon-svg"
              d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,.5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"
            ></path>
          </svg>
        </div>
      </button>
    </div>

    <div class="flex justify-center mtb-30" v-else-if="id === 'secondary'">
      <button
        id=""
        class="secondary border-red rounded-full hover-red hover-font-white flex-row-reverse"
      >
        <div class="btn-txt font-red">Close Application</div>
        <div class="icon icon-red font-20">
          <i class="fas fa-arrow-circle-right" aria-hidden="true"></i>
        </div>
      </button>
    </div>

    <div
      class="flex justify-center mtb-30"
      v-else-if="id === 'secondaryDisabled'"
    >
      <button
        disabled
        class="secondary border-red  rounded-full hover-red flex-row-reverse disabled"
      >
        <div class="btn-txt font-red">Close Application</div>
        <div class="icon">
          <svg
            id="svg"
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            viewBox="0 0 15.5 15.5"
          >
            <path
              class="icon-svg"
              d="M15.5,7.75A7.75,7.75,0,1,1,7.75,0,7.75,7.75,0,0,1,15.5,7.75Zm-6.29-1H3.5a.74.74,0,0,0-.75.75V8a.74.74,0,0,0,.75.75H9.21L6.85,11a.75.75,0,0,0,0,1.07l.35.34a.74.74,0,0,0,1.06,0l4.14-4.14a.74.74,0,0,0,0-1.06L8.24,3.07a.75.75,0,0,0-1.06,0l-.35.34a.76.76,0,0,0,0,1.08Z"
            ></path>
          </svg>
        </div>
      </button>
    </div>

    <div class="flex justify-center mtb-30" v-else-if="id === 'textButton'">
      <button class="rounded-4 hover-gray hover-font-red">
        <div class="btn-txt">Text Button</div>
      </button>
    </div>

    <div
      class="flex justify-center mtb-30"
      v-else-if="id === 'textButtonDisabled'"
    >
      <button disabled class="rounded-4 hover-gray hover-font-red disabled">
        <div class="btn-txt">Text Button</div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonComponentDS",
  props: ["id"],
};
</script>

<style scoped>
/*////////////////////

    BUTTONS 

////////////////////*/


button:hover.underline{ text-decoration: underline!important}
a.hover-underline:hover{ text-decoration: underline }

/* disabled */
button.disabled, 
.button.disabled{
    position: relative;
    pointer-events: none;
    background: var(--gray-400)!important;
    border-color: var(--gray-500)!important;
    z-index: 0;
}
button.disabled > .btn-txt, 
.button.disabled > .btn-txt{
    color: var(--gray-600)!important;
}
button.disabled > .icon{
    fill: gray!important;
    color: gray!important;
}

button, .button{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: max-content;
    padding: 0 20px;
    border: 0;
    font-size: 16px;
    cursor: pointer;
    background: transparent;
    transition: all .2s ease-in-out!important;
}

/* button secondary */
button.secondary, 
.button.secondary{
    border: 2px;
    border-style: solid;
    background: transparent;
}
/* button secondary */
button:disabled.secondary, 
.button.secondary.disabled{
    border: 0px;
    background: transparent;
}

/* button text-link */
button.text-link, 
.button.text-link{
    display: inline-block;
    width: max-content;
    padding: 0;
    height: auto;
}

/* button soft */
button.soft, 
.button.soft{
    width: 44px;
    padding: 0 15px!important;
    margin: 0!important;
}

button.soft .icon, 
.button.soft .icon{
    padding: 0!important;
    margin: 0!important;
}

button.soft-sml, 
.button.soft-sml{
    width: 24px;
    height: 24px;
    padding: 0 5px!important;
    margin: 0!important;
}

button.soft-sml .icon, 
.button.soft-sml .icon{
    padding: 0!important;
    margin: 0!important;
    font-size: 12px;
}



button.flex-row-reverse .icon, 
.button.flex-row-reverse .icon{
    padding: 0 10px 0 0;
    margin: 0 0 0 -4px!important;
}

button .icon, 
.button .icon{
    transition: all .05s ease-in-out!important;
}
button .btn-txt, 
a.button .btn-txt{
    transition: all .2s ease-in-out!important;
}

button .icon, 
.button .icon{
    position: relative;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

button .btn-txt, 
a.button .btn-txt{
    display: flex;
    justify-content: center;
    min-width: max-content;
    font-size: 16px;
}

/* Button : Secondary - border styles */
button.border-white, 
a.button.border-white{
    border-color: rgb(255, 255, 255);
}

button.border-black, 
a.button.border-black{
    border-color: black;
}

button.border-red, 
a.button.border-red{
    border-color: var(--red-800);
}

button.border-blue, 
a.button.border-blue{
    border-color: var(--blue-800);
}

button.border-green, 
a.button.border-green{
    border-color: var(--green-800);
}




/*//////////////////////////////// 
    Button - Hover styles 
////////////////////////////////*/

/* hover gray */
button.hover-gray:hover, 
a.button.hover-gray:hover{
    background: var(--gray-300)!important;
}

/* hover white */
button.hover-white-black:hover, 
a.button.hover-white-black:hover{
    background: white!important;
}

button.hover-white-black:hover .btn-txt, 
a.button.hover-white-black:hover .btn-txt{
    color: black!important;
}


button.hover-white-black:hover div.icon, 
a.button.hover-white-black:hover div.icon{
    fill: black!important;
    color: black!important;
}

button.hover-white-red:hover, 
a.button.hover-white-red:hover{
    background: white!important;
}
button.hover-white-red:hover .btn-txt, 
a.button.hover-white-red:hover .btn-txt{
    color: var(--red-800)!important;
}
button.hover-white-red:hover div.icon .icon-svg, 
a.button.hover-white-red:hover div.icon .icon-svg{
    fill: var(--red-800)!important;
}
button.hover-border-none:hover, 
a.button.hover-border-none:hover{
    border:2px transparent solid;
}


button.hover-black:hover, 
a.button.hover-black:hover{
    background: black!important;
}
button.hover-black:hover .btn-txt, 
a.button.hover-black:hover .btn-txt{
    color: white!important;
}
button.hover-black:hover > div.icon .icon-svg{
    fill: white!important;
}


button:hover.hover-red, 
a.button:hover.hover-red{
    background: var(--red-800)!important;
    color: white!important;
}

button.button.secondary:hover{
    border: solid transparent!important;
}

/* button.hover-red:hover .btn-txt, 
a.button.hover-red:hover .btn-txt{
    color: white!important;
} */


/* Hover Red BG - White text and or Icon */

/* button.hover-red:hover > div.icon > i, 
button.hover-red:hover > div.btn-txt, 
a.button.hover-red:hover > div.icon,
a.button.hover-red:hover > div.btn-txt {
    fill: white!important;
    color: white!important;
} */


/* Custom Button Styles */
#progress-btn{
    transition: all .2s ease-in-out;
}

button > .icon > i.icon-anim-right{
    left: 10px;
    transition: all .2s ease-in-out;
}
button:hover > .icon > i.icon-anim-right{
    left: 20px;
    /* transition: all .2s ease-in-out; */
}
</style>
<template>
  <div class="mtb-20">
    <p>{{ this.compLabel }} import link</p>

    <div class="import-link" @click="copyToClipboard()">
      <div class="flex align-center justify-between">
        <p class="font-14"><Prism language="html">{{ this.compLink }}</Prism></p>
        <div class="ml-20 icon" @click="copyToClipboard()">
          <i class="fa-solid fa-copy"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "prismjs";
import "prismjs/themes/prism.css";
import Prism from "vue-prism-component";
export default {
  name: "ImportLinkComponent",
  props: ["id"],
  data() {
    return {
      compLabel: "",
      compLink: "",
    };
  },
  mounted() {
    this.buildCompData();
  },
  methods: {
    copyToClipboard: function() {
      let codeToCopy = this.componentImportObject[this.id].link;
      this.$copyText(codeToCopy).then(
        function() {
          console.log("Copied");
        },
        function() {
          console.warn("Can not copy");
        }
      );
    },
    buildCompData: function() {
      let importComp = this.id;
      let comp = importComp.split("-")[0];
      let fileType = importComp.split("-")[1];
      this.compLabel = comp + "." + fileType;
      if (window.location.host === "devportal.iop.ohio.gov") {
        this.compLink =
          `<link rel="stylesheet" href="https://ds.iop.ohio.gov/src/components/` +
          comp +
          `/` +
          comp +
          `.` +
          fileType +
          `" type="text/css">`;
      } else {
        this.compLink =
          `<link rel="stylesheet" href="https://ds-stg.iop.ohio.gov/src/components/` +
          comp +
          `/` +
          comp +
          `.` +
          fileType +
          `" type="text/css">`;
      }
    },
  },
  components: {
    Prism,
  }
};
</script>

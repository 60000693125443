<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Buttons</h1>
              <div class="pagehead-link">
                <a href=""><img src="/html-components/images/svg/link.svg" alt=""/></a>
              </div>
            </div>
            <p>
              Since buttons allow users to make decisions, take specific
              actions, or link to a new page, they are one of the most important
              elements of our user interface. We have several buttons types for
              different scenarios. They will be placed in various locations
              throughout your user interface, but should always be thoughtful
              and easy to find. They should clearly communicate the action the
              user is about to take.
            </p>
          </div>
        </section>
        <div class="divider max-w1200"></div>
        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <section id="general-guidelines">
              <div class="content-band">
                <h2>General Guidelines</h2>
                <ul class="bullet-red">
                  <li>
                    Call to action buttons are 44px in height on desktop and
                    mobile. A Slim button pattern can be used in certain
                    scenarios outlined below and those can be 34px tall.
                  </li>
                  <li>
                    Call to action buttons have a 50% of height corner radius
                    (44px tall with a 22px top left, top right, bottom left, and
                    bottom right corner radius).
                  </li>
                  <li>
                    Button labels should be action oriented (Share, Buy,
                    Download, etc.) and should have no more than three words
                    with a preference for one to two. Each word on a button
                    should have the first letter capitalized (camel-case) except
                    the words "to" and "and".
                  </li>
                </ul>
                <p>
                  In general, any particular action should have the same button
                  style applied consistently throughout a
                  site/application/ecosystem. An exception to that would be an
                  instance where in one screen an action is the primary focus of
                  the user tasks and in another, relative to those options, is
                  an additional option to the primary task.
                </p>
              </div>
            </section>

            <div class="divider"></div>
            <section id="import-component">
              <div class="content-band">

                <h3>Import the CSS</h3>
                <ImportLinkComponent id="button-css" />

              </div>
            </section>
            <div class="divider"></div>

            <section id="primary-button">
              <div class="content-band">
                  <div class="container mb-30">
                  <h3>Primary Button</h3>
                  <p>
                    A primary buttons are utilized for the actions that are the
                    most important and highest priority to the users. Generally
                    these should be 'positive' actions. For example, in general a
                    'Delete or Remove' button should be a secondary or tertiary
                    button.
                  </p>
                </div>

                <div>Primary Button with Icon</div>
                <CodeView component="button" type="primary-button-regular">
                  <ButtonComponentDS id="primaryRed" />
                </CodeView>

                <div>Primary Button - Disabled</div>
                <CodeView component="button" type="primary-button-disabled">
                  <ButtonComponentDS id="primaryDisabled" />
                </CodeView>
              </div>
            </section>

            <div class="divider"></div>

            <section id="secondary-button">
              <div class="content-band">
                <div class="container mb-30">
                <h3>Secondary Button</h3>
                <p>
                  A secondary button is used when there is more than one action
                  that is important to the user on the same screen.
                </p>
                <p>
                  A secondary button can represent a less definitive action for
                  the user, for example save as draft versus submit. Another
                  example, a group of buttons with a cancel function, a save
                  function and a save plus continue. A tertiary button would be
                  used for cancel, a secondary button for save and a primary
                  button for save plus continue.
                </p>
                <div class="gray-250 p-30 max-w800 mtb-30">
                  <div class="font-red semibold">
                    Usage Guidelines
                  </div>
                  <p>
                    In general secondary buttons are dependant upon a primary
                    button being present, however there may be instances within
                    a given application where you want to maintain a secondary
                    button type for the same function throughout for visual
                    consistency.
                  </p>
                </div>
                </div>
                
                <div>Secondary Button with Icon</div>

                <CodeView component="button" type="secondary-button-regular">
                  <ButtonComponentDS id="secondary" />
                </CodeView>

                <div>Secondary Button - Disabled</div>
                <CodeView component="button" type="secondary-button-disabled">
                  
                  <ButtonComponentDS id="secondaryDisabled" />
                </CodeView>
              </div>
            </section>

            <div class="divider"></div>

            <section id="text-button">
              <div class="content-band">
                <div class="container mb-30">
                <h3>Text Button</h3>
                <p>
                  Text buttons are used for less important actions or when the
                  user wants to leave a journey (back or cancel).
                </p>
                <div class="gray-250 p-30 max-w800 mtb-30">
                  <div class="font-red semibold">
                    Usage Guidelines
                  </div>
                  <p>
                    Text buttons are typically used in concert with another
                    button, however, there may be times when its use alone is
                    appropriate. For example, on a profile screen to edit user
                    details.
                  </p>
                </div>
                </div>
                
                <div>Regular Text Button</div>

                <CodeView component="button" type="text-button-regular">
                  <ButtonComponentDS id="textButton" />
                </CodeView>

                <div>Disabled Text Button</div>
                <CodeView component="button" type="text-button-disabled">
                  
                  <ButtonComponentDS id="textButtonDisabled" />
                </CodeView>
              </div>
            </section>
          </div>
          <StickyTOC />
        </div>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import StickyTOC from "../../StickyTOC";
import ButtonComponentDS from "../Button/ButtonComponentDS.vue";
import CodeView from "../../CodeView";
import TempLeftNav from "../../TempLeftNav";
import SubFooter from "../../../components/SubFooter";
import ImportLinkComponent from "../../../components/ImportLinkComponent";
export default {
  name: "Button",
  data: function() {
    return {};
  },
  components: {
    CodeView,
    TempLeftNav,
    SubFooter,
    StickyTOC,
    ButtonComponentDS,
    ImportLinkComponent,
  },
};
</script>
